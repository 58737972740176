@import url("./app_styles.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .header {
        @apply font-semibold text-xl text-gray-800 leading-tight;
    }
    .btn_partial {
        @apply px-4 py-2 inline-flex items-center transition ease-in-out duration-150 focus:ring-2 focus:ring-offset-2 focus:outline-none tracking-widest rounded-md font-semibold text-xs border;
    }
    .primary_btn {
        @apply btn_partial bg-gray-800 border-transparent text-white uppercase hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900  focus:ring-indigo-500;
    }
    .secondary_btn {
        @apply btn_partial bg-white border-gray-300 text-gray-700 uppercase shadow-sm hover:bg-gray-50  focus:ring-indigo-500 disabled:opacity-25;
    }
    .welcome_btn {
        @apply btn_partial bg-white border-gray-300 text-gray-700 shadow-sm hover:bg-gray-50  focus:ring-indigo-500 disabled:opacity-25;
    }
    .danger_btn {
        @apply btn_partial bg-red-600 border-transparent text-white uppercase  hover:bg-red-500 active:bg-red-700   focus:ring-red-500;
    }
    .welcome-container {
        @apply relative flex justify-center min-h-screen dark:bg-gray-900 sm:items-center sm:pt-0 font-poppin;
    }
    .site-wrapper {
        @apply bg-site_background min-h-screen;
    }
    .dashboard {
        @apply mx-auto px-6 mt-6 sm:mt-0 xl:px-0 relative z-10 xl:max-w-[1680px] max-w-full;
    }
    .main-wrapper {
        @apply pt-[75px] sm:pt-0;
    }
    .dashborad-wrapper {
        @apply sm:rounded-lg flex md:flex-row flex-col-reverse;
    }
    .dashborad-wrapper-left {
        @apply text-gray-900 md:w-80 w-full;
    }
    .dashborad-wrapper-right {
        @apply text-gray-900 w-full mt-4 md:mt-0;
    }
    .dashborad-grid-section {
        @apply flex flex-row gap-2 justify-between;
    }
    .event-wrapper {
        @apply flex justify-between xl:grid grid-cols-3 flex-col;
    }
    .event-tab {
        @apply p-4 flex flex-row justify-between items-center shadow mb-4 font-poppin bg-white text-2xl;
    }
    .event-list {
        @apply flex flex-col overflow-auto h-full py-2 shadow bg-white;
    }
    .event-list-container {
        @apply flex bg-white border-b border-t border-[1px] border-r-transparent border-l-transparent border-gray-300;
    }
    .event-list-dates {
        @apply px-4 py-14 border-r border-gray-300 flex-[10] text-center;
    }
    .event-list-time {
        @apply mr-2 pl-4 pr-2 -translate-y-3 bg-white text-out_dates;
    }
    .event-list-events {
        @apply flex-1 relative flex-[90] border-l border-gray-300 flex flex-col h-full;
    }
    .event-w-bar {
        @apply absolute border-b border-event-300 top-[50%] z-10 w-full left-0;
    }
    .event-items {
        @apply relative z-20 text-white bg-blue-500 rounded p-1 flex;
    }
    .event-container {
        @apply flex flex-col flex-auto xl:h-[75vh] h-full ml-0 pl-0 md:pl-4 mb-4 xl:mb-0 w-auto sm:w-full transition-all;
    }
    .current_view {
        @apply flex relative opacity-100;
    }
    .calendar-wrapper {
        @apply flex flex-col font-poppin;
    }
    .calendar-container {
        @apply flex-col text-black text-center shadow mb-4 p-2 bg-white;
    }
    .calendar-controller {
        @apply flex items-center justify-between gap-4 py-2;
    }
    .calendar-inputs {
        @apply flex cursor-pointer items-center gap-2 text-gray-400;
    }
    .calendar-grid {
        @apply grid grid-cols-7 gap-1;
    }
    .calendar-title {
        @apply font-poppin;
    }
    .calendar-daysOfTheWeek {
        @apply mb-2 text-event-400;
    }
    .todo-container {
        @apply flex flex-col;
    }
    .todo-controller {
        @apply flex flex-row items-center justify-between border-b;
    }
    .todo-btn {
        @apply text-blue-500 py-3;
    }
    .todo-list-controller {
    }
    .todo-list-items {
        @apply pl-2 pt-2 flex flex-row border-b border-gray-300 gap-2 items-center;
    }
    .date_properties {
        @apply px-2 py-1 flex items-center justify-center;
    }
    .todays_date {
        @apply bg-out_dates text-today rounded-full;
    }
    .current_date {
        @apply text-current_dates;
    }
    .out_date {
        @apply text-out_dates;
    }
    .navigation {
        @apply bg-site_background border-b border-gray-100 xl:max-w-[1680px] max-w-full m-auto fixed w-full z-50 sm:w-auto sm:relative;
    }
    .link_wrapper {
        @apply space-x-8 sm:-my-px sm:ml-10 w-auto text-2xl transition-all top-auto relative right-auto bg-opacity-0 p-0 translate-y-0 flex-row mb-0;
    }
    .link_mobile_partial {
        @apply absolute flex w-full font-poppin p-6 justify-end right-0 top-full transition-all flex-col items-end;
    }
    .link_wrapper_mobile {
        @apply link_mobile_partial opacity-0 -translate-y-full pointer-events-none z-0 sm:opacity-100 sm:pointer-events-auto sm:relative;
    }
    .link_wrapper_mobile_active {
        @apply link_mobile_partial z-20 bg-white;
    }
    .nav_link_partial {
        @apply inline-flex items-center px-1 pt-1 text-lg font-medium leading-5 transition duration-150 ease-in-out ml-3 mb-4 sm:mb-0;
    }
    .active_nav {
        @apply nav_link_partial border-b-2 border-indigo-400 text-gray-900 focus:outline-none focus:border-indigo-700;
    }
    .inactive_nav {
        @apply nav_link_partial border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300;
    }
}
